/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `ApplicationLoading` renders the initial loading screen before the app is loaded.
 *
 *
 * ```
 * <ApplicationLoading />
 * ```
 *
 * @class ApplicationLoading
 *
 */

export default class ApplicationLoadingComponent extends Component {
  @service session;
  @service systemStatus;

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }
}
