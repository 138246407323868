import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

/** Map a Peering-API destination information based on its target value.
 * target can either be awsTarget or hvnTarget
 * This helper will need to be modified when more providers are added
 *
 * Example usage as an Ember template helper:
 * ```handlebars
 *  {{#let (peering-destination-info peering) as |peeringInfo|}}
 *   <td>
 *     {{#let
 *       (option-for-source-state
 *         'region' peeringInfo.provider
 *       ) as |option|
 *     }}
 *       <Flex @alignItems='center' @gap='2xs'>
 *         <Hds::Icon @name={{option.icon}} />
 *         <span
 *           class='hds-typography-body-200 hds-font-weight-regular hds-foreground-primary'
 *         >
 *           {{peeringInfo.accountId}}
 *         </span>
 *       </Flex>
 *     {{/let}}
 *   </td>
 *   <td>{{peeringInfo.vpc}}</td>
 *   <td>{{peeringInfo.region}}</td>
 * {{/let}}
```
 *
 * @param {array} args - 0th element of args must be an object.
 * @param {Object} args[0] - The object representation of a Peering from the cloud-network API
 */
export default class peeringDestinationInfo extends Helper {
  @service intl;

  compute([peering]) {
    if (!peering) {
      return null;
    }
    let accountId, provider, vpc, region, cidr;
    let peeringTarget = peering?.target;
    if (peeringTarget?.hvnTarget) {
      accountId = this.intl.t(
        'components.page.hvn-peerings.detail.peering-details.values.hcp-managed'
      );
      provider = peering.target.hvnTarget.hvn.location.region.provider;
      vpc = peering.target.hvnTarget.hvn.id;
      region = peering.target.hvnTarget.hvn.location.region.region;
      cidr = peering.target.hvnTarget.hvn.cidrBlock;
    }
    if (peeringTarget?.awsTarget) {
      accountId = peering.target.awsTarget.accountId;
      provider = peering.hvn.location.region.provider;
      vpc = peering.target.awsTarget.vpcId;
      region = peering.target.awsTarget.region;
      cidr = peering.target.awsTarget.cidr;
    }
    return { accountId, provider, vpc, region, cidr };
  }
}
