/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * Plain link which points to the correct status page for HCP in a given environment.
 *
 * ```
 * <Hcp::StatusPageLink />
 * ```
 */
export default class HcpStatusPageLinkComponent extends Component {
  @service systemStatus;

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }
}
