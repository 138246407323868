import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_IDP_SCIM,
  ACTION_GET,
  ACTION_LIST_TOKENS,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsDetailSettingsScimRoute extends Route {
  @service api;
  @service router;

  async beforeModel() {
    if (!variation('hcp-identity-scim-enabled')) {
      this.router.transitionTo('cloud.orgs.detail.settings');
    }
  }

  @permissionGuard({
    permission: `${PREFIX_IDP_SCIM}.${ACTION_GET}`,
  })
  @permissionGuard({
    permission: `${PREFIX_IDP_SCIM}.${ACTION_LIST_TOKENS}`,
  })
  async model() {
    const { organization } = this.modelFor('cloud.orgs.detail');
    let scimSettings = await this.api.scim.scimServiceIsScimEnabled(
      organization.id
    );
    let tokens = [];
    if (scimSettings?.isEnabled) {
      tokens = await this.api.scim.scimServiceListScimTokens(organization.id);
    }
    let ssoEnabled;
    let ssoTypeResponse;
    try {
      ssoTypeResponse = await this.api.sso.sSOManagementServiceGetSSOType(
        organization.id
      );
      if (ssoTypeResponse !== null) {
        ssoEnabled = true;
      }
    } catch (err) {
      // 404 is returned if SSOTypeResponse is of type 'None'
      if (err.status === 404) {
        ssoEnabled = false;
      }
    }
    return {
      isEnabled: scimSettings?.isEnabled,
      tenantUrl: scimSettings?.tenantUrl,
      organization,
      ssoEnabled,
      ssoType: ssoTypeResponse?.type,
      tokens,
    };
  }
}
