/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { warn } from '@ember/debug';

import { HashicorpCloudResourcemanagerResourceIDResourceType } from '@clients/cloud-resource-manager';

import { DEFAULT_ACCOUNT_ID } from 'billing-common/constants/billing';
/**
 *
 * Create form for a new project.
 *
 *
 * ```
 * <Page::Projects::Create
 *   @organization={{this.model.organization}}
 *   @projects={{array project1 project2}}
 * />
 * ```
 *
 * @class PageProjectsCreate
 *
 */

export const MAX_UPDATE_ADD_PROJECT_TO_BILLING_RETRIES = 3;
export const MIN_PROJECT_NAME_LENGTH = 3;
export const NAME_ALREADY_EXISTS_ERROR =
  'project with name already exists in the org';

/**
 * The current organization's projects list.
 * @argument projects
 * @type {Array}
 */

export default class PageProjectsCreateComponent extends Component {
  @service api;
  @service router;
  @service userContext;
  @service billing;
  @service flashMessages;
  @service intl;

  @tracked name = '';
  @tracked nameIsPristine = true;
  @tracked description = '';
  @tracked validationError;

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    this.nameIsPristine = false;

    yield this.validate.perform();

    if (this.validationError) {
      return;
    }

    const { name, description } = this;
    const { organization } = this.args;
    let resp;

    const payload = {
      name: name.trim(),
      description,
      parent: {
        id: organization.id,
        type: HashicorpCloudResourcemanagerResourceIDResourceType.ORGANIZATION,
      },
    };

    try {
      resp =
        yield this.api.resourceManager.project.projectServiceCreate(payload);
    } catch (e) {
      resp = yield e.json();

      switch (resp.message) {
        case NAME_ALREADY_EXISTS_ERROR: {
          resp.title = `${this.intl.t(
            'components.page.projects.create.project-name-must-be-unique'
          )}.`;
          resp.message = this.intl.t(
            'components.page.projects.create.the-project-name-is-already-in-use'
          );
          resp.details = [
            {
              field_violations: [
                {
                  field: 'name',
                  description: this.intl.t(
                    'components.page.projects.create.project-name-must-be-unique'
                  ),
                },
              ],
            },
          ];
          break;
        }
      }

      const modifiedResponse = new Response(JSON.stringify(resp), {
        status: e.status,
        statusText: e.statusText,
        headers: e.headers,
      });

      throw modifiedResponse;
    }

    const { project } = resp;

    const { updated, response } = yield* this.addProjectToBillingAccount(
      organization,
      project,
      DEFAULT_ACCOUNT_ID,
      MAX_UPDATE_ADD_PROJECT_TO_BILLING_RETRIES
    );

    // If we added the project to the billing account then update our
    // userContext with the response. Otherwise, delete the project and alert
    // the user that something went wrong. A project without a billing account
    // is not useful and this error needs to be resolved.
    if (updated) {
      this.userContext.billing = response;

      // Push in the new project so that userContext knows about the projects
      // count. This is helpful for knowing when a project can or cannot be
      // removed.
      this.userContext.projects = [...this.userContext.projects, project];

      this.flashMessages.success(
        this.intl.t('components.page.projects.create.project-created'),
        {
          content: this.intl.t(
            'components.page.projects.create.project-has-been-created',
            {
              projectName: payload.name,
            }
          ),
          actionText: this.intl.t(
            'components.page.projects.create.go-to-project'
          ),
          onAction: () => {
            return this.router.transitionTo(
              'cloud.orgs.detail.projects.detail',
              project.id
            );
          },
        }
      );
      this.router.transitionTo('cloud.orgs.detail.projects.list');
    } else {
      // Delete the project to clean up and then throw an error to trigger
      // the FormError component.
      yield this.api.resourceManager.project.projectServiceDelete(project.id);

      const cantCreateError = new Error();

      cantCreateError.message = this.intl.t(
        'components.page.projects.create.project-has-been-deleted-no-billing'
      );

      throw cantCreateError;
    }
  }

  @task
  *validate() {
    const { name } = this;
    const noSpecialCharactersRegex = /[^a-zA-Z0-9\s_\-.]/;

    try {
      const validationError = new Error();
      validationError.details = [];

      // Check that project name is not blank
      if (!name || !name.length) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-cannot-be-blank-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name is greater than the set minimum length
      if (name && name.length < MIN_PROJECT_NAME_LENGTH) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-must-be-minimum-length-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not contain disallowed special characters
      if (name && noSpecialCharactersRegex.test(name)) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-contains-disallowed-characters-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not begin with a space
      if (name && name.startsWith(' ')) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-begins-with-space-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not end with a space
      if (name && name.endsWith(' ')) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-ends-with-space-error'
                ),
              },
            ],
          },
        ];
      }

      if (validationError.details.length) {
        throw validationError;
      } else {
        yield (this.validationError = undefined);
      }
    } catch (e) {
      this.validationError = e;
    }
  }

  // addProjectToBillingAccount attempts to update the billing account with the
  // newly created project. Concurrently, it will retry up to maxRetries.
  *addProjectToBillingAccount(
    organization,
    project,
    billingAccountId = DEFAULT_ACCOUNT_ID,
    maxRetries = MAX_UPDATE_ADD_PROJECT_TO_BILLING_RETRIES
  ) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // We attach every project to the same billing account. Previously we
        // could use any previously-created project from the projects list to
        // find the billing account id and then attach the new project to it.
        // This daisy-chaining only worked if that project, selected because it
        // was the first project in the list response, had a billing account.
        // Instead, we should just attach it to the default id.
        const response = yield this.billing.addProjectToBillingAccount(
          organization.id,
          project.id,
          billingAccountId
        );

        // If there's no billing account, throw an error so that we catch it and
        // try again. This response will always 200 but if there's no billing
        // account then it returns an empty Billing class.
        if (!response.billingAccount) {
          throw new Error();
        }

        return {
          updated: true,
          response,
        };
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          warn('project create error', { id: 'project.create.index' });
          console.error('failed to create project', e); // eslint-disable-line no-console
        }
        continue;
      }
    }

    return {
      updated: false,
    };
  }
}
