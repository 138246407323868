/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

/**
 *
 * `PageProjectsListLastOpenedCard` shows the name and description of a project
 * with a link to the project detail.
 *
 *
 * ```
 * <Page::Projects::List::LastOpenedCard
 *   @project={{this.project}}
 * />
 * ```
 *
 * @class PageProjectsListLastOpenedCard
 *
 */

/**
 * The project object.
 * @argument project
 * @type {Object}
 */

export default class PageProjectsListLastOpenedCardComponent extends Component {
  @service analytics;

  @action
  goToLastOpened() {
    this.analytics.trackEvent('external_ui_hashicorp_project_last_opened');
  }
}
