import PaginatedRoute from 'core/routes/paginated-route';
import { inject as service } from '@ember/service';
import { TYPE_TERRAFORM_WORKSPACE } from 'common/utils/cloud-resource-types';
import {
  ACTION_LIST,
  PREFIX_TERRAFORM_WORKSPACES,
} from 'authz/utils/permission-types/index';
import { WorkspaceWithDisplayName } from 'core/utils/resource-name';

export default class TerraformWorkspacesListRoute extends PaginatedRoute {
  @service abilities;
  @service api;
  @service permissions;
  @service router;
  @service userContext;

  beforeModel() {
    if (!this.abilities.can('list terraform-workspaces')) {
      return this.router.transitionTo('terraform');
    }
  }

  async model({
    pageSize = 10,
    nextPageToken,
    previousPageToken,
    leafNameFilter,
  }) {
    const { id } = this.userContext.project;
    const currentPageSize = pageSize ? Number(pageSize) : pageSize;
    const { resources, pagination } =
      await this.api.resourceManager.resources.resourceServiceListAccessibleResources(
        `project/${id}`,
        undefined,
        TYPE_TERRAFORM_WORKSPACE,
        this.permissions.generate(PREFIX_TERRAFORM_WORKSPACES, ACTION_LIST),
        leafNameFilter,
        currentPageSize,
        nextPageToken,
        previousPageToken
      );
    return {
      currentPageSize,
      resources: resources.map((resource) =>
        WorkspaceWithDisplayName(resource)
      ),
      pagination,
      leafNameFilter,
      organizationId: this.userContext.organization.id,
      projectId: this.userContext.project.id,
    };
  }
}
