/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

import {
  ORGANIZATION_CREATED,
  ORGANIZATION_OWNERSHIP_CREATED,
  PROJECT_CREATED,
} from 'core/utils/consts/analytics-events/platform';

export const COUNTRY_CODE_US = 'US';
export const ORG_PURPOSE = {
  BUSINESS: 'business',
  PERSONAL: 'personal',
};
/**
 *
 * `PageOrganizationsCreate` renders the organization create form.
 *
 *
 * ```
 * <Page::Organizations::Create
 *  @onSuccess={{this.model.onSuccess}}
    @organizations={{this.model.organizations}}
    @userId={{this.model.userId}}
 * />
 * ```
 *
 * @class PageOrganizationsCreate
 *
 */

/**
 *
 * `onSuccess` is the async callback function for successfully creating an org.
 * @argument onSuccess;
 * @type {function}
 *
 */

/**
 *
 * `organizations` is a list of organizations a user owns.
 * @argument organizations;
 * @type {Array}
 * @required
 *
 */

/**
 *
 * `userId` is the userId of the user viewing this page.
 * @argument userId;
 * @type {string}
 * @required
 *
 */

export const NAME_ALREADY_EXISTS_ERROR =
  'An organization with this name already exists. Enter a different name.';

export default class PageOrganizationsCreateComponent extends Component {
  @service abilities;
  @service analytics;
  @service api;
  @service billing;
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  @tracked orgName = this.currentUser?.defaultOrgName;
  @tracked orgPurpose = variation('hcp-ui-flex-2-business-organization')
    ? undefined
    : ORG_PURPOSE.PERSONAL;
  @tracked termsAndConditionsAccepted = false;

  businessInfo = new TrackedObject({
    businessName: undefined,
    addressLine1: undefined,
    addressLine2: undefined, // Not used
    city: undefined,
    state: undefined,
    postalCode: undefined,
    countryCode: COUNTRY_CODE_US,
  });

  ORG_PURPOSE = ORG_PURPOSE;

  @action toggleTermsAndConditions() {
    this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted;
  }

  @task *validate() {
    const validation = new Error();
    const fieldViolations = [];

    validation.title = `${this.intl.t(
      'components.page.organizations.create.error.something-went-wrong'
    )}.`;
    validation.message = this.intl.t(
      'components.page.organizations.create.error.fix-the-errors-in-the-form-before-continuing'
    );
    validation.details = [];

    if (!this.orgName?.length) {
      fieldViolations.push({
        field: 'name',
        description: this.intl.t(
          'components.page.organizations.create.error.field-name-is-required',
          {
            fieldName: this.intl.t(
              'components.page.organizations.create.form.name.label'
            ),
          }
        ),
      });
    }

    if (this.orgPurpose === ORG_PURPOSE.BUSINESS) {
      if (!this.termsAndConditionsAccepted) {
        fieldViolations.push({
          field: 'terms_and_conditions',
          description: this.intl.t(
            'components.page.organizations.create.error.terms-and-conditions-accepted'
          ),
        });
      }

      if (!this.businessInfo.businessName) {
        fieldViolations.push({
          field: 'business_name',
          description: this.intl.t(
            'components.page.organizations.create.error.field-name-is-required',
            {
              fieldName: this.intl.t(
                'components.page.organizations.create.form.company.name'
              ),
            }
          ),
        });
      }

      if (!this.businessInfo.addressLine1) {
        fieldViolations.push({
          field: 'address_line1',
          description: this.intl.t(
            'components.page.organizations.create.error.field-name-is-required',
            {
              fieldName: this.intl.t(
                'components.page.organizations.create.form.company.address-line-1'
              ),
            }
          ),
        });
      }

      if (!this.businessInfo.city) {
        fieldViolations.push({
          field: 'city',
          description: this.intl.t(
            'components.page.organizations.create.error.field-name-is-required',
            {
              fieldName: this.intl.t(
                'components.page.organizations.create.form.company.city'
              ),
            }
          ),
        });
      }

      if (!this.businessInfo.countryCode) {
        fieldViolations.push({
          field: 'country_code',
          description: this.intl.t(
            'components.page.organizations.create.error.field-name-is-required',
            {
              fieldName: this.intl.t(
                'components.page.organizations.create.form.company.country'
              ),
            }
          ),
        });
      }

      if (fieldViolations.length) {
        validation.details.push({ field_violations: fieldViolations });
      }
    }

    if (this.submit.performCount > 0 && validation.details.length) {
      throw validation;
    }

    yield validation;
  }

  @task *save() {
    const { organization = {} } = yield this.createOrganization.perform();
    const { project = {} } = yield this.createProject.perform(organization.id);
    const { onSuccess } = this.args;

    yield this.userContext.setOrganization(organization);
    yield this.userContext.setProject(project);

    yield this.createDefaultBillingAccount.perform(
      organization.id,
      project.id,
      this.orgPurpose,
      this.businessInfo
    );

    // Perform this call after the organization has been set in userContext
    // so that we have permissions set in permissions service.
    if (
      this.orgPurpose === ORG_PURPOSE.BUSINESS &&
      this.abilities.can('create ownership in organization')
    ) {
      yield this.createOrgOwnership.perform({
        organization,
        businessInfo: this.businessInfo,
      });
    }

    this.flashMessages.success(
      this.intl.t('components.page.organizations.create.flash.success')
    );

    this.currentUser.organizations = [
      ...this.currentUser.organizations,
      organization,
    ];

    if (onSuccess && typeof onSuccess === 'function') {
      yield onSuccess({ organization, project });
    }
  }

  @task *submit(event) {
    event.preventDefault();

    yield this.validate.perform();
    yield this.save.perform();
  }

  @task *createOrganization() {
    const reqBody = { name: this.orgName.trim() };

    let resp;
    try {
      resp =
        yield this.api.resourceManager.org.organizationServiceCreate(reqBody);
    } catch (e) {
      resp = yield e.json();

      switch (resp.message) {
        case NAME_ALREADY_EXISTS_ERROR: {
          resp.title = `${this.intl.t(
            'components.page.organizations.create.error.organization-name-must-be-unique'
          )}.`;
          resp.message = this.intl.t(
            'components.page.organizations.create.error.the-organization-name-is-already-in-use'
          );
          resp.details = [
            {
              field_violations: [
                {
                  field: 'name',
                  description: this.intl.t(
                    'components.page.organizations.create.error.organization-name-must-be-unique'
                  ),
                },
              ],
            },
          ];
          break;
        }
      }

      const modifiedResponse = new Response(JSON.stringify(resp), {
        status: e.status,
        statusText: e.statusText,
        headers: e.headers,
      });

      throw modifiedResponse;
    }

    this.analytics.trackEvent(ORGANIZATION_CREATED, {
      payload: reqBody,
      resp,
    });

    return resp;
  }

  @task *createOrgOwnership({ organization, businessInfo }) {
    const reqBody = [
      organization.id, // organizationOwnershipOrganizationId
      undefined, // organizationOwnershipUpdatedByUserId
      undefined, // organizationOwnershipCreatedAt
      undefined, // organizationOwnershipUpdatedAt
      businessInfo.businessName, // organizationOwnershipBusinessBusinessName
      businessInfo.addressLine1, // organizationOwnershipBusinessAddressLine1
      businessInfo.addressLine2, // organizationOwnershipBusinessAddressLine2
      businessInfo.city, // organizationOwnershipBusinessCity
      businessInfo.state, // organizationOwnershipBusinessState
      businessInfo.postalCode, // organizationOwnershipBusinessPostalCode
      businessInfo.countryCode, // organizationOwnershipBusinessCountryCode
    ];
    let resp;

    try {
      resp =
        yield this.api.billing.organizationOwnership.organizationOwnershipServiceCreate(
          ...reqBody
        );
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        // eslint-disable-next-line
        console.error(e);
      }
    }

    this.analytics.trackEvent(ORGANIZATION_OWNERSHIP_CREATED, {
      payload: reqBody,
      resp,
    });

    return resp;
  }

  @task *createProject(orgId) {
    const reqBody = {
      name: this.currentUser?.defaultProjectName,
      parent: {
        id: orgId,
        type: 'ORGANIZATION',
      },
    };
    const resp =
      yield this.api.resourceManager.project.projectServiceCreate(reqBody);
    this.analytics.trackEvent(PROJECT_CREATED, {
      payload: reqBody,
      resp,
    });
    return resp;
  }

  @task
  *createDefaultBillingAccount(orgId, projectId, orgPurpose, businessInfo) {
    if (!this.userContext.billing?.billingAccount) {
      const defaultBilling = yield this.billing.createDefaultBillingAccount(
        orgId,
        projectId,
        orgPurpose === ORG_PURPOSE.BUSINESS
          ? businessInfo.countryCode
          : COUNTRY_CODE_US
      );

      this.userContext.billing = defaultBilling;
    }
  }
}
