import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import setSentryUser from 'core/utils/sentry-user';
import cookieAuthEnabled from 'core/helpers/cookie-auth-enabled';

// Beware: using `variation` in this route does not have organization context.
// While one could check for variations at the environment or user context,
// flags relying on organization targeting will always return false.
// import { variation } from 'ember-launch-darkly';

import {
  ORGANIZATION_CREATED,
  PROJECT_CREATED,
} from 'core/utils/consts/analytics-events/platform';
import { clearRedirectTarget, setRedirectTarget } from 'hcp/utils/redirect';
import config from '../config/environment';

export default class CloudRoute extends Route {
  @service analytics;
  @service acceptInvitation;
  @service api;
  @service billing;
  @service abilities;
  @service config;
  @service currentUser;
  @service intl;

  @service session;
  @service operation;
  @service router;
  @service orgPreferences;

  @action
  willTransition(transition) {
    // When navigating back from an error page, the model hooks do not re-fire
    // so we catch the new transition here and call the redirect helper.
    if (
      (transition.from.attributes?.status >= 400 &&
        transition.targetName === 'cloud.index') ||
      transition.from.attributes?.path === '404'
    ) {
      this.redirectToOverview();
    }
  }

  async beforeModel(transition) {
    // HCPIE-2179: we need to load the user object after authentication
    // we cannot do this in the session service because of Launch Darkly...
    if (cookieAuthEnabled() && !this.currentUser?.user) {
      await this.currentUser.load();
    }

    const targetURL = transition?.intent?.url;
    // don't set target if we're going to sign-out or to the root
    if (
      targetURL &&
      targetURL !== '/' &&
      transition.targetName !== 'sign-out'
    ) {
      setRedirectTarget(targetURL);
    }

    this.session.requireAuthentication(transition, config.APP.loginRoute);
  }

  async model() {
    let userResp;
    let orgResp;
    let error;
    let sessionData = this.session.data;
    let invitationToken = this.acceptInvitation.invitationToken;
    let isCookieAuthEnabled = cookieAuthEnabled();
    let isSSOUser = isCookieAuthEnabled
      ? this.currentUser.user.isSSO
      : sessionData.authenticated.user.isSSO;

    if (!isCookieAuthEnabled) {
      try {
        userResp = await this.api.iam.iamServiceGetCurrentUserPrincipal(true);
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    }

    // Only consume the invite if they have an invitationToken and they are not
    // an SSO user.
    if (invitationToken && !isSSOUser) {
      await this.acceptInvitation.consumeInvite(invitationToken);
    }

    if (!isCookieAuthEnabled) {
      await this.setUser(userResp);
    }

    let { organizations } =
      await this.api.resourceManager.org.organizationServiceList();

    this.currentUser.organizations = organizations;

    this.acceptInvitation.maybeRedirectToOrg(organizations);
    if (orgResp) {
      return orgResp;
    }
    return { error };
  }

  afterModel() {
    /**
     * HCPIE-1925
     * Delete the redirectTarget value to prevent issues with other users later.
     * Since afterModel is only called upon successful authentication,
     * this ensures the value is preserved on failed authentication
     */
    clearRedirectTarget();
  }

  async setUser(userResp) {
    let user = (userResp && userResp.userPrincipal) || {};
    let sessionUser = this.session.data.authenticated.user;
    this.currentUser.user = { ...sessionUser, ...user };
    setSentryUser(getOwner(this), user.id);
  }

  async redirect(_, transition) {
    this.billing.start();
    this.operation.start();
    // Only redirect if the target is this route ('cloud.index')
    if (['cloud.index'].includes(transition.to.name)) {
      this.redirectToOverview();
    }
  }

  deactivate() {
    this.billing.stop();
    this.operation.stop();
  }

  async createOrganization(payload = {}) {
    let resp =
      await this.api.resourceManager.org.organizationServiceCreate(payload);

    this.analytics.trackEvent(ORGANIZATION_CREATED, {
      payload,
      resp,
    });

    return resp;
  }

  async createProject(payload = {}) {
    let resp =
      await this.api.resourceManager.project.projectServiceCreate(payload);

    this.analytics.trackEvent(PROJECT_CREATED, {
      payload,
      resp,
    });

    return resp;
  }

  async redirectToOverview() {
    let { organizations } = this.currentUser;
    if (!organizations || organizations.length === 0) {
      /** We don't check organization permissions here (which at the time of writing
       * just ensure the user hasn't created more orgs than their quota and the user is
       * NOT an SSO user). However, in this case, the user has no org at all, and we want
       * to ensure that they have a single organization - so we'll allow them to create one
       * via the `cloud.orgs.create` route.
       */
      this.router.transitionTo('cloud.orgs.create');
    } else {
      const orgPreferences = await this.orgPreferences.getOrganization();
      const { id: savedOrgId } = orgPreferences ? orgPreferences : {};

      // Check to see if there is an org preference stored in local storage.
      // IF there is, AND it corresponds to an org in the list, THEN select it;
      // ELSE default to the first org in the list.
      const orgIdToSelect =
        savedOrgId && organizations.some((org) => org.id === savedOrgId)
          ? savedOrgId
          : organizations[0].id;
      // Go to the redirect logic for an org. This allows LaunchDarkly config
      // to be set before determining where to transition to.
      this.router.transitionTo('cloud.orgs.detail.redirect', orgIdToSelect);
    }
  }
}
