import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isAwsProviderNetwork } from 'networks-common/utils/networks';
import {
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_NETWORK,
} from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnsDetailRoute extends Route {
  @service api;
  @service userContext;

  async model({ hvn_id }) {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = await this.api.network.get(
      organization.id,
      project.id,
      hvn_id
    );
    let networkDependencies = await this.api.network.listDependencies(
      organization.id,
      project.id,
      network.id
    );
    networkDependencies = networkDependencies.dependencies;
    let { peerings } = await this.api.network.listPeerings(
      organization.id,
      project.id,
      network.id
    );

    /**
     * TODO: Remove Promise.all call and filter against peerings
     * when API is updated to include peering dependencies
     */

    let peeringDep = Promise.all(
      peerings.map((peering) => {
        if (peering.target?.hvnTarget) {
          let networkId = peering.target?.hvnTarget.hvn.id;
          return this.api.network.listDependencies(
            organization.id,
            project.id,
            networkId
          );
        } else {
          return Promise.resolve();
        }
      })
    );
    peeringDep = await peeringDep;
    peerings = peerings.map((peering, i) => {
      let peeringHvnTargetConsulClusterDependencies = peeringDep[
        i
      ]?.dependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      let networkConsulClusterDependencies = networkDependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      if (
        networkConsulClusterDependencies?.length > 0 &&
        peeringHvnTargetConsulClusterDependencies?.length > 0
      ) {
        peering.isLocked = true;
      } else {
        peering.isLocked = false;
      }
      peering.showLink = peering.target.hvnTarget ? false : true;
      return peering;
    });
    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id
    );
    let tgwAttachments;
    if (isAwsProviderNetwork(network)) {
      let awsTgwAttachments = await this.api.network.listTGWAttachments(
        organization.id,
        project.id,
        network.id
      );
      tgwAttachments = awsTgwAttachments.tgwAttachments;
    } else {
      tgwAttachments = [];
    }
    this.userContext.setResource(network, TYPE_NETWORK);
    return {
      dependencies: networkDependencies,
      network,
      routes,
      peerings,
      tgwAttachments,
    };
  }
}
