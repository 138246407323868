import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const PROJECT_DETAIL_ROUTE_ROOT = 'cloud.orgs.detail.projects.detail';

const ORGANIZATION_REDIRECT_ROUTE = 'cloud.orgs.detail.redirect';
const ORGANIZATION_HCP_WITH_TFC_ROUTE = 'cloud.orgs.detail.hcp-with-terraform';
const BLACKLISTED_SIDEBAR_ROUTES = [
  ORGANIZATION_REDIRECT_ROUTE,
  ORGANIZATION_HCP_WITH_TFC_ROUTE,
];

export default class CloudOrgsDetailController extends Controller {
  @service router;
  @service('resources.organization') organizations;
  @service currentUser;
  @tracked organizationsLinks = this.organizations?.links;

  // The nav should show when you're in a middleground between orgs and project
  // detail. If the current route name contains the project detail route with
  // a partial match or if there's a route in the blacklist array, the sidebar
  // will stay hidden.
  get shouldShowNav() {
    const { currentRouteName } = this.router;
    return (
      !currentRouteName.includes(PROJECT_DETAIL_ROUTE_ROOT) &&
      !BLACKLISTED_SIDEBAR_ROUTES.includes(currentRouteName)
    );
  }

  get hasNoAccessibleProjects() {
    const { projects = [] } = this.model ?? {};
    return projects.length < 1;
  }

  get projectsLink() {
    return this.organizations.links.find((link) => link.id === 'projects');
  }
}
