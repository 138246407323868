/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  isVaultRadarPaid,
  isVaultRadarDeleted,
  isVaultRadarTrial,
  TENANT_STATE,
  TENANT_TYPE,
  VAULT_RADAR_TENANT_STATES,
} from '../../../utils/vault-radar';
import { task, timeout } from 'ember-concurrency';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import {
  PREFIX_VAULT_RADAR,
  VAULT_RADAR_ADMIN,
  VAULT_RADAR_USERS,
} from 'authz/utils/permission-types/index';

const VAULT_RADAR_ADMIN_PERMISSION = `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_ADMIN}`;

const DELAY_FOR_VAULT_RADAR_TRANSITION = 2000;

export default class PageVaultRadarComponent extends Component {
  @service intl;
  @service analytics;
  @service userContext;
  @service config;
  @service api;
  @service permissions;
  @service('config') appConfig;
  @service flashMessages;

  @tracked tenantData;
  @tracked hasExistingTenant;

  @action
  trackWaitlistClick() {
    this.analytics.trackEvent('vault_radar_beta_waitlist_link_click');
  }

  @task
  *setProvisionedTenant() {
    const { project, organization } = this.userContext;

    try {
      yield this.api.vaultRadar.tenant.provisionTenant(project.id, {
        location: {
          organizationId: organization.id,
        },
      });

      this.flashMessages.success(
        this.intl.t('components.page.vault-radar.toast.setup.success')
      );

      // Buy some time to show the success message
      yield timeout(DELAY_FOR_VAULT_RADAR_TRANSITION);

      window.location.href = this.vaultRadarLink;
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        // eslint-disable-next-line
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t('components.page.vault-radar.toast.setup.failed')
      );
    }
  }

  get vaultRadarLink() {
    const { project } = this.userContext;
    return `${this.appConfig.app.vaultRadarDomain}/projects/${project.id}`;
  }

  get tenantState() {
    // We still allow vault-radar users to see their data during delinquency, just not
    // start new trials
    const VIEWABLE_TENANT_STATES = [
      TENANT_STATE.PROVISIONED,
      TENANT_STATE.DELINQUENT,
    ];

    const { tenantData, hasExistingTenant } = this.args;
    const { state, type } = tenantData || {};
    const canProvision = !hasExistingTenant || type === TENANT_TYPE.INTERNAL;

    const isTenantClaimed =
      !canProvision && state === TENANT_STATE.NEW && this.isAdmin;
    const isActiveBeta =
      type === TENANT_TYPE.BETA && VIEWABLE_TENANT_STATES.includes(state);
    const isActivePaid = VIEWABLE_TENANT_STATES.includes(state);
    const isNewPaidTenant =
      isVaultRadarPaid(type) && state === TENANT_STATE.NEW && this.isAdmin;
    const isNewBeta =
      state === TENANT_STATE.NEW && type === TENANT_TYPE.BETA && this.isAdmin;
    const isDeletedTenant =
      isVaultRadarPaid(type) && isVaultRadarDeleted(state);
    const isTrialEnded = isVaultRadarTrial(type) && isVaultRadarDeleted(state);

    // For now, support Beta separately from Trial, where Customer and Standard have the
    // same behavior. After launch, beta and customer states will be deprecated.
    const isActiveTrial =
      isVaultRadarTrial(type) && state === TENANT_STATE.PROVISIONED;
    const isNewTrial = isVaultRadarTrial(type) && state === TENANT_STATE.NEW;

    switch (true) {
      case isDeletedTenant:
        return VAULT_RADAR_TENANT_STATES.DELETED_TENANT;
      case isTrialEnded:
        return VAULT_RADAR_TENANT_STATES.TRIAL_ENDED;
      case isTenantClaimed:
        return VAULT_RADAR_TENANT_STATES.CLAIMED_TENANT;
      case isActiveBeta:
        return VAULT_RADAR_TENANT_STATES.ACTIVE_BETA;
      case isActiveTrial:
        return VAULT_RADAR_TENANT_STATES.ACTIVE_TRIAL;
      case isNewPaidTenant:
        return VAULT_RADAR_TENANT_STATES.NEW_PAID;
      case isNewBeta:
        return VAULT_RADAR_TENANT_STATES.NEW_BETA;
      case isNewTrial:
        return VAULT_RADAR_TENANT_STATES.NEW_TRIAL;
      case isActivePaid:
        return VAULT_RADAR_TENANT_STATES.ACTIVE_PAID;
      default:
        return VAULT_RADAR_TENANT_STATES.SETUP_REQUIRED;
    }
  }

  get isAdmin() {
    return this.permissions.has(VAULT_RADAR_ADMIN_PERMISSION);
  }

  get isActive() {
    const activeStates = [
      VAULT_RADAR_TENANT_STATES.ACTIVE_PAID,
      VAULT_RADAR_TENANT_STATES.ACTIVE_BETA,
      VAULT_RADAR_TENANT_STATES.ACTIVE_TRIAL,
    ];
    return activeStates.includes(this.tenantState);
  }

  get isNew() {
    const newStates = [
      VAULT_RADAR_TENANT_STATES.NEW_PAID,
      VAULT_RADAR_TENANT_STATES.NEW_BETA,
      VAULT_RADAR_TENANT_STATES.NEW_TRIAL,
    ];
    return newStates.includes(this.tenantState);
  }

  get canProvisionTenant() {
    return this.isNew && this.isAdmin;
  }

  get ctaButtonText() {
    return this.tenantState === VAULT_RADAR_TENANT_STATES.NEW_TRIAL
      ? this.intl.t('components.page.vault-radar.cta-trial')
      : this.intl.t('components.page.vault-radar.cta');
  }

  get alertSettings() {
    switch (this.tenantState) {
      case VAULT_RADAR_TENANT_STATES.CLAIMED_TENANT:
        return {
          title: this.intl.t('components.page.vault-radar.alert.claimed.title'),
          description: this.intl.t(
            'components.page.vault-radar.alert.claimed.description'
          ),
          color: 'warning',
          icon: 'alert-triangle',
        };
      case VAULT_RADAR_TENANT_STATES.ACTIVE_BETA:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.beta-active.title'
          ),
          color: 'highlight',
          icon: 'info',
        };
      case VAULT_RADAR_TENANT_STATES.NEW_BETA:
        return {
          title: this.intl.t('components.page.vault-radar.alert.beta.title'),
          description: this.intl.t(
            'components.page.vault-radar.alert.beta.description'
          ),
          color: 'highlight',
          icon: 'info',
        };
      case VAULT_RADAR_TENANT_STATES.SETUP_REQUIRED:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.setup-required.title'
          ),
          description: this.intl.t(
            'components.page.vault-radar.alert.setup-required.description'
          ),
          color: 'warning',
          icon: 'alert-triangle',
        };
      case VAULT_RADAR_TENANT_STATES.DELETED_TENANT:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.deleted-tenant.title'
          ),
          description: this.intl.t(
            'components.page.vault-radar.alert.deleted-tenant.description'
          ),
          color: 'critical',
          icon: 'alert-diamond',
        };
      case VAULT_RADAR_TENANT_STATES.TRIAL_ENDED:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.trial-ended.title'
          ),
          description: this.intl.t(
            'components.page.vault-radar.alert.trial-ended.description'
          ),
          color: 'highlight',
          icon: 'info',
        };
      default:
        return null;
    }
  }

  /**
   * @description Returns the qualified qualtrics link to our waitlist form
   * @returns {string}
   */
  get waitlistLink() {
    return this.intl.t(
      'components.page.vault-radar.overview.card.waitlist.href'
    );
  }
}
