import incidentIoStatusPage from 'core/utils/incident-io-status-page';

export function initialize(appInstance) {
  const config = appInstance.resolveRegistration('config:environment');
  const systemStatusService = appInstance.lookup('service:system-status');

  if (config?.APP?.deployTarget === config?.DEPLOY_TARGETS?.PROD) {
    systemStatusService.config = incidentIoStatusPage.PRODUCTION;
  } else {
    systemStatusService.config = incidentIoStatusPage.NON_PRODUCTION;
  }
}

export default {
  name: 'incident-io-status-page',
  after: ['page-config'],
  initialize,
};
