import { createAuth0Client } from '@auth0/auth0-spa-js';
// docs for auth0 client: https://auth0.github.io/auth0-spa-js/

import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import config from '../config/environment';
import { warn } from '@ember/debug';
import { inject as service } from '@ember/service';
import { USER_LOGGED_IN } from 'core/utils/consts/analytics-events/platform';

// a custom rule populates this key on the id token, it represents
// the primary identity where the email/email_verified is derived.
// the key is the same across all environments.
export const PRIMARY_IDENTITY =
  'https://auth.hashicorp.com/primary-identity-provider';
export const CONNECTION_STRATEGY =
  'https://auth.hashicorp.com/connection/strategy';

function createClient({ client_id } = {}) {
  let clientArgs = {
    ...config.APP.auth0,
  };
  clientArgs.authorizationParams.redirect_uri = `${window.location.origin}/login/callback`;
  if (client_id) {
    // this overwrite the default client_id from the config
    clientArgs.clientId = client_id;
  }
  return createAuth0Client(clientArgs);
}
export default class Auth0 extends BaseAuthenticator {
  @service analytics;

  client = createClient();

  async restore() {
    let client;
    try {
      client = await this.client;
    } catch (e) {
      console.log('createAuth0Client() failed during restore()');
      console.log(e);

      // If the client failed to create on a previous try, this will allow the
      // authenticator to eventually recover by re-creating it.
      // Note that if there are multiple failures in a row, this will still
      // result in the session being invalidated and an attempt to logout all
      // other tabs.
      this.client = createClient();
      client = await this.client;
    }
    let accessToken;
    try {
      accessToken = await client.getTokenSilently();
    } catch (e) {
      warn(`${e.error}, ${e.error_description}`, {
        id: 'auth0-authenticator-silent-token-rejection',
      });
      throw e;
    }

    // the getUser decodes the id token and does not perform any io
    let userData = await client.getUser();

    return {
      accessToken,
      user: {
        ...userData,
        isSSO: userData[CONNECTION_STRATEGY] === 'samlp',
        primaryIdentity: userData[PRIMARY_IDENTITY],
      },
    };
  }

  async authenticate(args = {}) {
    let client;
    if (args.client_id) {
      this.client = createClient({ client_id: args.client_id });
      client = await this.client;
    } else {
      try {
        client = await this.client;
      } catch (e) {
        console.log('createAuth0Client() failed during authenticate()');
        console.log(e);

        // If the client failed to create on a previous try, this will allow the
        // authenticator to eventually recover by re-creating it.
        // Note that if there are multiple failures in a row, this will still
        // result in the session being invalidated and an attempt to logout all
        // other tabs.
        this.client = createClient();
        client = await this.client;
      }
    }
    let sessionData;
    let { connection, screen_hint, redirect_uri, ...appState } = args;

    // Allows us to silent auth the user when they visit the sign-in route
    if (args.isSilentAuth) {
      return { ...(await this.restore()), ...appState };
    }

    if (!args.isCallback) {
      // pass through the appState this object gets passed in the sign-up-form component via extraAuthArgs
      // the terms and privacy policy acceptance are the only thing we thread through now
      client.loginWithRedirect({
        appState,
        connection,
        screen_hint,
        redirect_uri,
      });
      // don't resolve while the page redirects
      return new Promise(() => {});
    }

    let resp = await client.handleRedirectCallback();
    appState = resp.appState;
    sessionData = await this.restore();
    // merge appState onto sessionData
    sessionData = { ...sessionData, ...appState };

    // We set these context values to null to avoid leaking any auth codes from
    // the callback URL to Segment
    this.analytics.trackEvent(
      USER_LOGGED_IN,
      {
        type: 'auth0',
        session: {
          user: sessionData.user.id,
        },
      },
      {
        context: {
          page: {
            url: null,
            path: null,
            search: null,
          },
        },
      }
    );

    return sessionData;
  }

  async invalidate() {
    let client;
    try {
      client = await this.client;
    } catch (e) {
      console.log('createAuth0Client() failed during invalidate()');
      console.log(e);

      // If the client failed to create on a previous try, this will allow the
      // authenticator to eventually recover by re-creating it.
      // Note that if there are multiple failures in a row, this will still
      // result in the session being invalidated and an attempt to logout all
      // other tabs.
      this.client = createClient();
      client = await this.client;
    }
    await client.logout({
      returnTo: window.location.origin,
      client_id: config.APP.auth0.client_id,
    });
    // don't resolve while the page redirects
    return new Promise(() => {});
  }
}
