import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  CREATE_ORIGIN_TFC,
  CREATE_ORIGIN_HCP_CLOUD,
} from 'hcp/utils/constants';

export default class CloudOrgsCreateRoute extends Route {
  @service api;
  @service billing;
  @service currentUser;
  @service operation;
  @service router;
  @service userContext;

  /**
   * The operations start and stop below is necessary because we don't have an
   * org id for polling. This prevents tests from silently hanging as well.
   */
  activate() {
    this.billing.stop();
    this.operation.stop();
  }

  deactivate() {
    this.billing.start();
    this.operation.start();
  }

  async model({ orgCreateOrigin, redirectAfterCreate }) {
    const { organizations } =
      await this.api.resourceManager.org.organizationServiceList();

    let onSuccess = async ({ organization, project }) => {
      this.router.transitionTo(
        'cloud.orgs.detail.projects.detail',
        organization.id,
        project.id
      );
    };

    // Update the currentUser's orgs list.
    this.currentUser.organizations = [...organizations];

    await this.userContext.setOrganization(null);
    await this.userContext.setProject(null);

    const shouldRedirectToTarget =
      redirectAfterCreate && orgCreateOrigin === CREATE_ORIGIN_HCP_CLOUD;
    if (shouldRedirectToTarget) {
      onSuccess = async () => {
        this.router.transitionTo(redirectAfterCreate);
      };
    }

    // If the user has this feature flag enabled, the redirect instead takes
    // them to the success page which allows them to redirect to HCP or TFC.
    if (orgCreateOrigin === CREATE_ORIGIN_TFC) {
      onSuccess = async ({ organization }) => {
        this.router.transitionTo(
          'cloud.orgs.detail.hcp-with-terraform',
          organization.id
        );
      };
    }

    return {
      organizations,
      userId: this.userContext.currentUser?.user.id,
      onSuccess,
      orgCreateOrigin,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.orgCreateOrigin = null;
      controller.redirectAfterCreate = null;
    }
  }
}
