import Service from '@ember/service';
import { timeout, dropTask } from 'ember-concurrency';
import { warn } from '@ember/debug';
import { isTesting } from '@embroider/macros';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import incidentIoStatusPage from 'core/utils/incident-io-status-page';
import { INCIDENT_IO_STATUS_PAGE_STATUS } from 'core/utils/constants';

/** @typedef {('OPERATIONAL'|'DEGRADED'|'OUTAGE'|'MAINTENANCE')} DisplayStatus */

/**
 * @typedef {Object} HcpSystemStatus
 * @property {incidentIoStatusPageStatus} hcp
 * @property {incidentIoStatusPageStatus} consul
 * @property {incidentIoStatusPageStatus} packer
 * @property {incidentIoStatusPageStatus} vault
 */

export const ONE_MINUTE_MS = 60 * 1000;

export const HCP_STATUS = {
  OPERATIONAL: 'OPERATIONAL',
  DEGRADED: 'DEGRADED',
  OUTAGE: 'OUTAGE',
  MAINTENANCE: 'MAINTENANCE',
};

export const STATUS_PAGE_CARDINALITY_MAPPINGS = {
  [INCIDENT_IO_STATUS_PAGE_STATUS.FULL_OUTAGE]: 5,
  [INCIDENT_IO_STATUS_PAGE_STATUS.PARTIAL_OUTAGE]: 4,
  [INCIDENT_IO_STATUS_PAGE_STATUS.DEGRADED_PERFORMANCE]: 3,
  [INCIDENT_IO_STATUS_PAGE_STATUS.UNDER_MAINTENANCE]: 2,
  [INCIDENT_IO_STATUS_PAGE_STATUS.OPERATIONAL]: 1,
};

export const INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS = {
  [INCIDENT_IO_STATUS_PAGE_STATUS.OPERATIONAL]: HCP_STATUS.OPERATIONAL,
  [INCIDENT_IO_STATUS_PAGE_STATUS.DEGRADED_PERFORMANCE]: HCP_STATUS.DEGRADED,
  [INCIDENT_IO_STATUS_PAGE_STATUS.PARTIAL_OUTAGE]: HCP_STATUS.OUTAGE,
  [INCIDENT_IO_STATUS_PAGE_STATUS.FULL_OUTAGE]: HCP_STATUS.OUTAGE,
  [INCIDENT_IO_STATUS_PAGE_STATUS.UNDER_MAINTENANCE]: HCP_STATUS.MAINTENANCE,
  DEFAULT: null,
};

export const isIdOneOf = (idsAllowList) => (incidentIoStatusPageComponent) =>
  idsAllowList.includes(incidentIoStatusPageComponent?.id);

export const toStatus = (incidentIoStatusPageComponent) =>
  incidentIoStatusPageComponent?.current_status;

export const byStatusSeverityDsc = (statusA, statusB) => {
  const severityA = STATUS_PAGE_CARDINALITY_MAPPINGS[statusA];
  const severityB = STATUS_PAGE_CARDINALITY_MAPPINGS[statusB];
  return severityB - severityA;
};

export const deriveAggregateComponentStatus = (
  incidentIoStatusPageComponents,
  componentIds
) => {
  const [topSeverityStatus = INCIDENT_IO_STATUS_PAGE_STATUS.OPERATIONAL] =
    incidentIoStatusPageComponents
      .filter(isIdOneOf(componentIds))
      .map(toStatus)
      .sort(byStatusSeverityDsc);

  return topSeverityStatus;
};

export default class SystemStatusService extends Service {
  // NOTE: This must be overridden by an Ember instance initializer when the app boots.
  // SEE: hcp/app/instance-initializers/incident-io-status-page.js
  config = incidentIoStatusPage.NON_PRODUCTION;

  /** @type {DisplayStatus} */
  @tracked hcp = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked consul = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked packer = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked vault = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  get statusPageUrl() {
    return this.config.baseUrl;
  }

  /** @public */
  start() {
    warn('system-status service starting', {
      id: 'service.system-status.start',
    });

    this._pollStatus.perform();
  }

  /** @public */
  stop() {
    warn('system-status service stopping', {
      id: 'service.system-status.stop',
    });
    this._pollStatus.cancelAll();
  }

  /** @private */
  @dropTask *_pollStatus() {
    let errCounter = 1;

    while (true) {
      try {
        let {
          hcp = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          consul = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          packer = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          vault = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT,
        } = yield this._fetchHcpSystemStatus();

        this.hcp =
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS[hcp] ||
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.consul =
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS[consul] ||
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.packer =
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS[packer] ||
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.vault =
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS[vault] ||
          INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

        errCounter = 1;
      } catch (e) {
        if (macroCondition(isDevelopingApp())) console.error(e); // eslint-disable-line

        this.hcp = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.consul = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.packer = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.vault = INCIDENT_IO_STATUS_PAGE_TO_HCP_STATUS_MAPPINGS.DEFAULT;

        errCounter++;
      }

      if (isTesting()) return;
      yield timeout(ONE_MINUTE_MS * errCounter);
    }
  }

  /** Derives HCP system statuses from incident.io component data.
   * @returns {Promise<HcpSystemStatus>}
   * @private
   * @throws */
  async _fetchHcpSystemStatus() {
    let components = await this._fetchIncidentIoStatusPageAffectedComponents();

    let hcp = deriveAggregateComponentStatus(
      components,
      this.config.hcpComponentIds
    );
    let consul = deriveAggregateComponentStatus(
      components,
      this.config.consulComponentIds
    );
    let packer = deriveAggregateComponentStatus(
      components,
      this.config.packerComponentIds
    );
    let vault = deriveAggregateComponentStatus(
      components,
      this.config.vaultComponentIds
    );

    return { hcp, consul, packer, vault };
  }

  /**
   * @returns {Array[Object]}
   * @private */
  async _fetchIncidentIoStatusPageAffectedComponents() {
    try {
      let rawStatus = await fetch(this.config.jsonApiUrl);

      if (rawStatus.status !== 200) {
        throw new Error(
          `Failed to fetch incident.io status page data: ${rawStatus.statusText}`
        );
      }

      let { ongoing_incidents = [], in_progress_maintenances = [] } =
        await rawStatus.json();

      let affected_components = [
        ...ongoing_incidents,
        ...in_progress_maintenances,
      ].flatMap(({ affected_components = [] }) => affected_components);

      return affected_components;
    } catch (error) {
      throw new Error(`Failed to fetch incident.io status page data`);
    }
  }
}
