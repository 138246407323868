import deepMerge from 'deepmerge';
import { isTesting } from '@embroider/macros';

/** @type {String} - the ID of the deployed `index.html` page configuration `<script>` tag */
export const PAGE_CONFIG_DEFAULT_DOM_ELEMENT_ID = 'config';

/** @type {Array.<String>} - the key names of config properties to override from deployed page config over Ember envinronment config */
export const PAGE_CONFIG_OVERRIDE_KEYS = ['segment', 'stripe', 'launchDarkly'];

/**
 * Injects runtime configuration based on the deployed `index.html` that hosts Cloud UI's shell `hcp` Ember application.
 *   1. Ember environment `config` configuration object will be merged with allow-listed page configuration defined in `PAGE_CONFIG_OVERRIDE_KEYS`
 *   2. Ember environment `config.APP` configuration merged with ALL page configuration
 *
 * NOTE: Cloud UI uses the custom `multi-html` addon to produce one `index-*.html` file per deployed environment based on the base `index.html` file.
 *       Example: `index.html` → `index-dev.html`,`index-int.html`,`index-prod.html`
 *       During deployment, one of these HTML files will be renamed to `index.html` to be served as the default HTML page.
 *
 * @param {*} appInstance - the Ember application instance
 */
export function initialize(appInstance) {
  let config = appInstance.resolveRegistration('config:environment');
  let pageConfig = readPageConfig(config);

  // Set merged application configuration for consumption by Ember services, controllers etc.
  config.APP = deepMerge(config.APP, pageConfig);

  // Override base Ember environment config with deployment specific values from index.html script tag.
  // NOTE: This is necessary for 3rd party Ember addons which read from `config` instead of `config.APP`
  const deploymentConfigOverrides = pluckPageConfig(
    pageConfig,
    PAGE_CONFIG_OVERRIDE_KEYS
  );
  Object.keys(deploymentConfigOverrides).forEach(
    (key) =>
      (config[key] = deepMerge(config[key], deploymentConfigOverrides[key]))
  );

  deletePageConfig(config);
}

/**
 * Reads the deplyoment specific configuration values from the deployed `index.html` file's DOM.
 * @param {Object} environmentConfig
 * @returns {Object}
 */
export function readPageConfig(environmentConfig = {}) {
  const pageConfigElementId =
    environmentConfig?.APP?.configTagId || PAGE_CONFIG_DEFAULT_DOM_ELEMENT_ID;
  const scriptTag = document.getElementById(pageConfigElementId);

  let pageConfig = {};

  if (!scriptTag) {
    console.error(
      `ERROR: Could not find <script> element with ID #${pageConfigElementId}`
    );
    return pageConfig;
  }

  try {
    pageConfig = JSON.parse(scriptTag.textContent);
  } catch (error) {
    console.error(
      `ERROR: Could not parse JSON page config from element with ID #${pageConfigElementId}`
    );
    console.error(error);
  }

  return pageConfig;
}

/**
 * Deletes the deplyoment specific configuration values from the deployed `index.html` file's DOM.
 *
 * NOTE: Doesn't delete the config element under test, to reduce console output and also
 *       to simplify debugging, because ordering of tests can't be guaranteed and deleting
 *       from the DOM mutates the state of the `hcp/tests/index.html` file for subsequent tests.
 *
 * @param {Object} environmentConfig
 */
export function deletePageConfig(environmentConfig = {}) {
  const pageConfigElementId =
    environmentConfig?.APP?.configTagId || PAGE_CONFIG_DEFAULT_DOM_ELEMENT_ID;
  try {
    const scriptTag = document.getElementById(pageConfigElementId);
    if (!isTesting()) {
      scriptTag.parentElement.removeChild(scriptTag);
    }
  } catch (error) {
    console.error(
      `ERROR: Could not remove element with ID #${pageConfigElementId}`
    );
    console.error(error);
  }
}

/**
 * Plucks allow-listed keys from the deployed page configuration values.
 * @param {Object} pageConfig
 * @param {Array.<String>} keys
 * @returns {Object}
 */
export function pluckPageConfig(pageConfig = {}, keys = []) {
  return keys.reduce((overrides, key) => {
    if (!pageConfig[key]) return overrides;
    return { ...overrides, [key]: pageConfig[key] };
  }, {});
}

export default {
  before: ['incident-io-status-page'],
  initialize,
};
