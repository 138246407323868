/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageAcceptInvitation` is the parent wrapper component for the user accept invite page.
 *
 * Usage:
 * ```
 * <Page::AcceptInvitation
 *  @invitationToken={{@invitationToken}}
 *  @organizationName={{@organizationName}}
 * />
 * ```
 *
 * @class PageAcceptInvitation
 *
 */

/**
 *
 * `invitationToken` is a required argument. It is the invite token for the
 * organization a user is being invited to. In addition to being used to accept the
 * invitation, it is also used to to extract the organization name for the
 * marketing accept-invite page here
 * https://github.com/hashicorp/cloud-ui/blob/28eed551cfedeacdf05863d7e35cd2587e64a98b/hcp/app/routes/accept-invitation.js#L14.
 * @argument invitationToken;
 * @type {String}
 *
 * `organizationName` is a required argument. It is the name of the organization that
 * the user is being invited to.
 * @argument organizationName;
 * @type {String}
 */

export default class PageAcceptInvitationComponent extends Component {}
