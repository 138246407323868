export const VAULT_RADAR_TENANT_STATES = {
  ACTIVE_BETA: 'ACTIVE_BETA',
  ACTIVE_PAID: 'ACTIVE_PAID',
  ACTIVE_TRIAL: 'ACTIVE_TRIAL',
  NEW_BETA: 'NEW_BETA',
  NEW_PAID: 'NEW_PAID',
  SETUP_REQUIRED: 'SETUP_REQUIRED',
  NEW_TRIAL: 'NEW_TRIAL',
  CLAIMED_TENANT: 'CLAIMED_TENANT',
  DELETED_TENANT: 'DELETED_TENANT',
  TRIAL_ENDED: 'TRIAL_ENDED',
  SERVICE_CUTOFF: 'SERVICE_CUTOFF',
};

export const TENANT_TYPE = {
  BETA: 'BETA',
  INTERNAL: 'INTERNAL',
  POC: 'POC',
  CUSTOMER: 'CUSTOMER',
  STANDARD: 'STANDARD',
  TRIAL: 'TRIAL',
};

export const TENANT_STATE = {
  NEW: 'NEW',
  PROVISIONED: 'PROVISIONED',
  DELINQUENT: 'DELINQUENT',
  DEPROVISIONING: 'DEPROVISIONING',
  DELETED: 'DELETED',
};

export const isVaultRadarDeleted = (tenantState) => {
  const serviceCutoffStates = [
    TENANT_STATE.DEPROVISIONING,
    TENANT_STATE.DELETED,
  ];
  return serviceCutoffStates.includes(tenantState);
};

export const isVaultRadarTrial = (tenantType) => {
  const trialTenantStates = [TENANT_TYPE.BETA, TENANT_TYPE.TRIAL];
  return trialTenantStates.includes(tenantType);
};

export const isVaultRadarPaid = (tenantType) => {
  const paidTenantStates = [
    TENANT_TYPE.CUSTOMER,
    TENANT_TYPE.STANDARD,
    TENANT_TYPE.INTERNAL,
    TENANT_TYPE.POC,
  ];
  return paidTenantStates.includes(tenantType);
};
