import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import * as Sentry from '@sentry/ember';

import {
  ACTION_GET,
  ACTION_SET_NAME,
  PREFIX_BILLING_ACCOUNTS,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsListRoute extends Route {
  @service api;
  @service billing;
  @service currentUser;

  @service operation;
  @service userContext;
  @service router;

  deactivate() {
    // When we leave this route, we should start polling again.
    this.billing.start();
    this.operation.start();
  }

  async model() {
    let { organizations } =
      await this.api.resourceManager.org.organizationServiceList();

    // This nullifies the userContext which has side-effects such as hiding
    // the global app frame's sidebar. Most routes, especially at the 'cloud'
    // level, will set these if they aren't set. Unsetting the org also unsets
    // the project.
    await this.userContext.setOrganization(null);

    // When we enter this route, the operation and billing service won't have
    // an organization id to use for polling.
    this.billing.stop();
    this.operation.stop();

    // Utilizing promises here allows us to get the billing details
    // associated with each of the orgs without the API calls
    // blocking one another
    let organizationPromises = organizations.map(async (organization) => {
      let canActivateFlexError = `Failed to assign canActivateFlex property to ${organization.name}`;
      let { allowedPermissions } =
        await this.api.resourceManager.org.organizationServiceTestIamPermissions(
          organization.id,
          {
            permissions: [
              `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
              `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
            ],
          }
        );

      let BILLING_PERMISSION = allowedPermissions.includes(
        `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`
      );

      if (BILLING_PERMISSION) {
        // Currently we enforce always having a project. If there's only one project
        // left in an org, we block the user from deleting it, so we can safely assume
        // that one project should always exist. However, there is still the potential
        // for an org to be created without a project or an org + project to be created
        // without a billing account association if api calls fail mid-flow.
        try {
          let { projects } =
            await this.api.resourceManager.project.projectServiceList(
              'ORGANIZATION',
              organization.id
            );

          let billing = await this.billing.getBilling(
            organization.id,
            projects[0].id
          );

          // orgs with a current or upcoming flex contract, or that are
          // delinquent are not eligible for contract activation
          organization.canActivateFlex =
            !billing?.hasConsumptionPayment &&
            !billing?.flex?.upcomingContract &&
            !billing?.status?.isDelinquent;
        } catch (error) {
          //eslint-disable-next-line no-console
          if (macroCondition(isDevelopingApp())) {
            console.error(`${canActivateFlexError} - ${error}`);
          }
          Sentry.captureException(`${canActivateFlexError} - ${this.error}`);
        }
      }

      organization.canRename = allowedPermissions.includes(
        `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`
      );

      return organization;
    });

    try {
      this.userContext.currentUser.organizations =
        await Promise.all(organizationPromises);
    } catch (e) {
      this.userContext.currentUser.organizations = organizations;
      Sentry.captureException(e);
    }

    return {
      organizations: this.userContext.currentUser?.organizations,
      userId: this.userContext.currentUser?.user?.id,
      isSsoUser: this.userContext.currentUser.isSsoEnabled,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.contractActivation = null;
    }
  }
}
