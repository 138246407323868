/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { assert } from '@ember/debug';

/**
 *
 * `HcpAlertTerraformPermissionsUnification` is a alert template that renders
 * information about the unification efforts between HCP and Terraform. This
 * component is only a base component that can be customized in any page and
 * exports the API of HDS alert via the Core::MarketingAlert component.
 *
 *
 * ```
 * <Hcp::Alert::TerraformUnification />
 * ```
 *
 * @class HcpAlertTerraformPermissionsUnification
 *
 */

export default class HcpAlertTerraformPermissionsUnificationComponent extends Component {
  @service alertStates;

  @tracked _isDismissed = true;

  constructor() {
    super(...arguments);

    const { id } = this.args;

    assert(`@id must be provided.`, id);

    this.fetchAlertState();
  }

  @action
  async close() {
    const { id } = this.args;
    await this.alertStates.setAlert({ id, isDismissed: true });
    this._isDismissed = true;
  }

  @action
  didInsert() {
    this.fetchAlertState();
  }

  async fetchAlertState() {
    const { id } = this.args;
    const alertState = await this.alertStates.getAlert(id);
    const { isDismissed } = alertState ?? {};
    this._isDismissed = Boolean(isDismissed);
  }

  get isOpen() {
    return !this._isDismissed;
  }

  get classNames() {
    const classes = ['hcp-alert-terraform-unification'];
    return classes.join(' ');
  }
}
