/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { DEFAULT_ACCOUNT_ID } from 'billing-common/constants/billing';

/**
 *
 * `PageOrganizationsList` renders a table of organizations.
 *
 *
 * ```
 * <Page::Organizations::List
 *   @contractActivation={{this.contractActivation}}
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageOrganizationsList
 *
 */

/**
 *
 * `contractActivation` indicates whether or not we are
 * within the self serve contract activation workflow.
 * @argument contractActivation;
 * @type {Boolean}
 * @required
 *
 */

/**
 *
 * `model` is an object an `organizations` key.
 * @argument model;
 * @type {Object}
 * @required
 *
 */

export default class PageOrganizationsListComponent extends Component {
  @service abilities;
  @service currentUser;
  @service intl;

  DEFAULT_ACCOUNT_ID = DEFAULT_ACCOUNT_ID;

  get canCreateOrg() {
    let organizations = this.args?.model?.organizations;
    return this.abilities.can('create organizations', organizations, {
      userId: this.args?.model?.userId,
      isSsoUser: this.args?.model?.isSsoUser,
    });
  }

  // Returns both orgName column and actions column if outside of the
  // contract activation workflow. Returns only orgName if inside the
  // activation workflow.
  get columns() {
    const orgName = [
      {
        key: 'name',
        label: this.intl.t(
          'components.page.organizations.list.table.headers.name'
        ),
      },
    ];
    const actions = [
      {
        key: 'actions',
        label: this.intl.t(
          'components.page.organizations.list.table.headers.options'
        ),
        align: 'right',
      },
    ];

    return this.args?.contractActivation ? orgName : [...orgName, ...actions];
  }

  // returns either all orgs or a filtered list if within the
  // contract activation flow - orgs should NOT appear in the
  // contract activation flow if they are already on Flex,
  // have an upcoming Flex transition or are delinquent
  get organizations() {
    const orgs = this.args?.model?.organizations;

    const orgsAvailableForFlexActivation = orgs.filter((org) => {
      return org.canActivateFlex;
    });

    return this.args?.contractActivation
      ? orgsAvailableForFlexActivation
      : orgs;
  }

  get shouldShowOrgCreateButton() {
    return (
      this.canCreateOrg &&
      !this.args?.contractActivation &&
      this.organizations.length > 0
    );
  }
}
