/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import templateString from 'core/utils/template-string';

/**
 *
 * `AzureBashCodeSnippetComponent` renders the Azure bash code snippet component
 *
 *
 * ```
 * <Page::HvnPeeringsDetail::ContentTabs::Azure::CodeSnippets::Bash
 *   @model={{@model}}
 * />
 * ```
 *
 * @class AzureConsulInboundOutboundRulesSnippetComponent
 *
 */
/**
 *
 * `model` is an object containing peering;
 * @argument model;
 * @type {Object}
 *
 */

let service_principal_access = templateString`AAD_APPLICATION_ID=${'application_id'}
az ad sp create --id $AAD_APPLICATION_ID`;

let create_service_principal_role = templateString`AZURE_SUBSCRIPTION_ID=${'subscription_id'}
az role definition create --output none --role-definition "{
  \\"name\\": \\"hcp-hvn-peering-access\\",
  \\"description\\": \\"Perform HCP cross-tenant network peering\\",
  \\"actions\\": [
      \\"Microsoft.Network/virtualNetworks/read\\",
      \\"Microsoft.Network/virtualNetworks/virtualNetworkPeerings/read\\",
      \\"Microsoft.Network/virtualNetworks/virtualNetworkPeerings/write\\",
      \\"Microsoft.Network/virtualNetworks/peer/action\\"
  ],
  \\"assignableScopes\\": [
      \\"/subscriptions/$AZURE_SUBSCRIPTION_ID/\\",
  ]
}"`;

let create_an_assignment = templateString`AAD_APPLICATION_ID=${'application_id'}
AZURE_SUBSCRIPTION_ID=${'subscription_id'}
RESOURCE_GROUP_NAME=${'resource_group_name'}
VNET_NAME=${'vnet_name'}
ROLE_NAME=${'role_name'}

az role assignment create \\\

    --role $ROLE_NAME \\\

    --assignee $AAD_APPLICATION_ID \\\

    --scope "/subscriptions/$AZURE_SUBSCRIPTION_ID/resourceGroups/$RESOURCE_GROUP_NAME/providers/Microsoft.Network/virtualNetworks/$VNET_NAME"`;
export default class AzureBashCodeSnippetComponent extends Component {
  get applicationId() {
    return this.args.model.peering.target.azureTarget.applicationId;
  }

  get vnetName() {
    return this.args.model.peering.target.azureTarget.vnetName;
  }

  get subscriptionId() {
    return this.args.model.peering.target.azureTarget.subscriptionId;
  }

  get resourceGroupName() {
    return this.args.model.peering.target.azureTarget.resourceGroupName;
  }

  get servicePrincipalAccessSnippet() {
    let string = service_principal_access({
      application_id: this.applicationId,
    });
    return string;
  }

  get createServicePrincipalRoleSnippet() {
    let string = create_service_principal_role({
      subscription_id: this.subscriptionId,
    });
    return string;
  }

  get createAnAssignmentSnippet() {
    let string = create_an_assignment({
      application_id: this.applicationId,
      subscription_id: this.subscriptionId,
      resource_group_name: this.resourceGroupName,
      vnet_name: this.vnetName,
      role_name: 'hcp-hvn-peering-access',
    });
    return string;
  }
}
