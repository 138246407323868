/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 *
 * `PageAcceptInvitationMain` renders an unauthenticated view to
 * display a message showing the user has been invited to HCP.
 * This component does not handle setting an invitation token to the session.
 *
 * Usage:
 * ```
 * <Page::AcceptInvitation::Main
 *   @invitationToken={{@invitationToken}}
 *   @organizationName={{@organizationName}}
 * />
 * ```
 *
 * @class PageAcceptInvitationMain
 *
 */

/**
 *
 * `invitationToken` is a required argument. It is the invite token for the
 * organization a user is being invited to. In addition to being used to accept the
 * invitation, it is also used to to extract the organization name for the
 * marketing accept-invite page here
 * https://github.com/hashicorp/cloud-ui/blob/28eed551cfedeacdf05863d7e35cd2587e64a98b/hcp/app/routes/accept-invitation.js#L14.
 * @argument invitationToken;
 * @type {String}
 *
 * `organizationName` is a required argument. It is the name of the organization that
 * the user is being invited to.
 * @argument organizationName;
 * @type {String}
 *
 */

export default class PageAcceptInvitationMainComponent extends Component {
  @service acceptInvitation;
  @service config;
  @service router;
  @service session;

  @action
  async accept(invitationToken) {
    // This will set the `invitationToken` in localStorage to be consumed in the
    // cloud route either now or after redirecting through the auth flow.
    this.acceptInvitation.invitationToken = invitationToken;

    if (this.session.isAuthenticated) {
      this.router.transitionTo('cloud');
    } else {
      await this.session.authenticate(
        `authenticator:${this.config.authenticatorType}`
      );
    }
  }
}
