import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

export default class ResourcesOrganizationService extends Service {
  @service abilities;
  @service userContext;
  @service intl;

  // This needs to stay a lowercase string to match with the ember ability type.
  type = 'organization';

  get links() {
    const { organization } = this.userContext;
    const links = [];

    if (organization) {
      links.push({
        icon: 'file-text',
        id: 'projects',
        models: [organization.id],
        route: 'cloud.orgs.detail.projects',
        text: pluralize(this.intl.t('nav.project.header')),
      });

      if (this.abilities.can('get log-entries')) {
        links.push({
          icon: 'logs',
          id: 'organization-logs',
          models: [organization.id],
          route: 'cloud.orgs.detail.monitoring.platform-logs.resource',
          text: this.intl.t('nav.organization.logs'),
        });
      }

      if (this.abilities.can('list log-streaming')) {
        links.push({
          icon: 'logs',
          id: 'audit-logs-streaming',
          models: [organization.id],
          route: 'cloud.orgs.detail.monitoring.audit-logs.streaming.index',
          text: this.intl.t('nav.organization.settings.audit-logs-streaming'),
        });
      }

      if (this.abilities.can('list user')) {
        links.push({
          icon: 'users',
          id: 'access-control',
          query: { org_id: organization.id, project_id: null },
          route: 'cloud.access-control',
          text: this.intl.t('nav.organization.settings.access-control'),
        });
      }

      if (this.abilities.can('view billing-account')) {
        links.push({
          icon: 'credit-card',
          id: 'billing',
          query: { org_id: organization.id, project_id: null },
          route: 'billing.accounts',
          text: this.intl.t('nav.organization.settings.billing'),
        });
      }

      links.push({
        icon: 'settings',
        id: 'settings',
        models: [organization.id],
        route: 'cloud.orgs.detail.settings',
        text: this.intl.t('nav.organization.settings.header'),
      });
    }

    return links;
  }
}
