// this is a copy of
// https://github.com/mainmatter/ember-simple-auth/blob/0b4bebb95a74080cc20256ebd65cbb7e5890611f/packages/ember-simple-auth/addon/authenticators/test.js
//
//
const sessionData = {
  accessToken: 'fake-access-token',
  expiration: Date.now() + 60 * 60 * 1000,
  user: {
    email: 'test@hcp.com',
    email_verified: true,
    nickname: 'testuser',
    connection: 'auth0',
    auth_time: Math.floor(Date.now() / 1000),
  },
};

import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import { inject as service } from '@ember/service';
import cookieAuthEnabled, {
  LOCAL_DEV_COOKIE,
} from 'core/helpers/cookie-auth-enabled';
import cookies from 'js-cookie';

export default class LocalDev extends BaseAuthenticator {
  @service currentUser;
  @service router;

  async restore(data = {}) {
    if (cookieAuthEnabled) {
      await this.currentUser.load();
    }

    let mergedData = {
      ...data,
      ...sessionData,
    };
    return Promise.resolve(mergedData);
  }

  async authenticate(data = {}) {
    let mergedData = {
      ...data,
      ...sessionData,
    };

    if (cookieAuthEnabled()) {
      cookies.set(LOCAL_DEV_COOKIE, btoa(mergedData));
    }

    return Promise.resolve(mergedData);
  }

  async invalidate() {
    if (cookieAuthEnabled()) {
      cookies.remove(LOCAL_DEV_COOKIE);
      this.router.transitionTo('sign-in');
      return;
    }

    return Promise.resolve();
  }
}
