/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action, set } from '@ember/object';

import { supportTicketPrefillHref } from 'core/utils/support-ticket';

/**
 *
 * `PageUsersInvite` displays the create form for inviting a HCP User.
 *
 *
 * ```
 * <Page::Invites::Invite @organization={{this.model.organization}} />
 * ```
 *
 * @class PageUsersInvite
 *
 */

/**
 * @argument organization
 * @type {Object}
 */

/**
 * @description A complex regular expression converted from the API's GO regex
 * to ensure synchronization between the UI and API validation logic. This regex
 * is employed for client-side validation during typing. Corresponding API tests
 * are incorporated in the integration test file of this component to maintain
 * consistency.
 * @see {@link https://github.com/asaskevich/govalidator/blob/a9d515a09cc289c60d55064edec5ef189859f172/patterns.go#L7}
 * for the original Regex used by the API at the time of this comment.
 */
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-\u0080-\uFFFF]+@[a-zA-Z0-9-\u0080-\uFFFF]+(\.[a-zA-Z0-9-\u0080-\uFFFF]+)*\.[a-zA-Z\u0080-\uFFFF]{1,}$/u;

const EXISTING_EMAIL_ERROR_MESSAGE =
  'user principals with following emails are already members: ';
const EXISTING_PENDING_EMAIL_ERROR_MESSAGE = 'user invites already exist';

function findDuplicates(users) {
  const seenEmails = new Set();
  const duplicates = [];

  users.forEach(([user, index]) => {
    if (seenEmails.has(user.inviteeEmail)) {
      duplicates.push([user, index]);
    } else {
      seenEmails.add(user.inviteeEmail);
    }
  });

  return duplicates;
}

class Invitee {
  @tracked inviteeEmail;
  @tracked role;
  @tracked isPristine = true;

  constructor({ inviteeEmail, role, isPristine = true }) {
    this.inviteeEmail = inviteeEmail;
    this.role = role;
    this.isPristine = isPristine;
  }
}

export default class PageUsersInviteComponent extends Component {
  @service api;
  @service abilities;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  @tracked users = [];
  @tracked validationError;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @action
  addEmptyUser() {
    this.users = [...this.users, new Invitee({ inviteeEmail: '', role: '' })];
  }

  @action
  removeUserByIndex(indexToRemove) {
    this.users = [
      ...this.users.slice(0, indexToRemove),
      ...this.users.slice(indexToRemove + 1),
    ];
  }

  @action
  findExistingUserIndices(existingMemberEmails, errorType) {
    const { users } = this;

    return users
      .map((user, index) => [user, index])
      .filter(([user]) => {
        if (errorType === 'invite') {
          return existingMemberEmails.some(
            (member) => member.description === user.inviteeEmail
          );
        } else {
          return existingMemberEmails.includes(user.inviteeEmail);
        }
      });
  }

  @action
  displayExistingEmails(...args) {
    const [users, errorType] = args;

    const validationError = new Error();
    validationError.details = [];

    // Find existing org users and/or existing pending invitations
    const existingUsers = this.findExistingUserIndices(users, errorType);

    if (existingUsers.length) {
      validationError.details = [
        ...validationError.details,
        {
          field_violations: [
            ...existingUsers.map(([, index]) => {
              return {
                field: `inviteeEmail[${index}]`,
                description: this.intl.t(
                  `components.page.access-control.users.invite.error.${errorType}-exists`
                ),
              };
            }),
          ],
        },
      ];
    }

    if (validationError.details.length) {
      throw validationError;
    }
  }

  @task
  *validate() {
    const { users } = this;

    try {
      const validationError = new Error();
      validationError.details = [];

      const userTuples = users.map((user, index) => {
        return [user, index];
      });

      // Find empty emails addresses.
      const usersWithEmptyEmail = userTuples.filter(([user]) => {
        return !user.inviteeEmail;
      });

      // Find invalid emails addresses.
      const usersWithInvalidEmail = userTuples.filter(([user]) => {
        return user.inviteeEmail && !EMAIL_REGEX.test(user.inviteeEmail);
      });

      // Find duplicate emails addresses.
      const duplicatedUsers = findDuplicates(userTuples);

      if (usersWithEmptyEmail.length) {
        validationError.details = [
          ...validationError.details,
          ...usersWithEmptyEmail.map(([, index]) => {
            return {
              field_violations: [
                {
                  field: `inviteeEmail[${index}]`,
                  description: this.intl.t(
                    'components.page.access-control.users.invite.form.cannot-be-blank'
                  ),
                },
              ],
            };
          }),
        ];
      }

      if (usersWithInvalidEmail.length) {
        validationError.details = [
          ...validationError.details,
          ...usersWithInvalidEmail.map(([, index]) => {
            return {
              field_violations: [
                {
                  field: `inviteeEmail[${index}]`,
                  description: this.intl.t(
                    'components.page.access-control.users.invite.form.enter-a-valid-email-address'
                  ),
                },
              ],
            };
          }),
        ];
      }

      if (duplicatedUsers.length) {
        validationError.details = [
          ...validationError.details,
          ...duplicatedUsers.map(([, index]) => {
            return {
              field_violations: [
                {
                  field: `inviteeEmail[${index}]`,
                  description: this.intl.t(
                    'components.page.access-control.users.invite.form.cannot-invite-two-users-with-same-email'
                  ),
                },
              ],
            };
          }),
        ];
      }

      if (validationError.details.length) {
        throw validationError;
      } else {
        yield (this.validationError = undefined);
      }
    } catch (e) {
      this.validationError = e;
    }
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    const { organization } = this.args;
    const { users } = this;

    // Simulate a touch event to expose all inline errors.
    users.map((user) => {
      set(user, 'isPristine', false);
      return user;
    });

    if (users.length == 0) {
      throw new Error('You must invite at least one user');
    }

    yield this.validate.perform();

    if (this.validationError) {
      return;
    }

    const payload = {
      invitations: users.map(({ inviteeEmail, role }) => {
        return { inviteeEmail, role };
      }),
    };

    try {
      yield this.api.invitation.invitationsServiceCreateOrganizationInvitations(
        organization.id,
        payload
      );

      this.flashMessages.success(
        this.intl.t(
          'components.page.access-control.users.invite.success.users-invited',
          {
            numUsers: users.length,
          }
        ),
        {
          content: this.intl.t(
            'components.page.access-control.users.invite.success.you-may-grant-project-level-roles'
          ),
          linkText: this.intl.t(
            'components.page.access-control.users.invite.success.learn-more-about-project-roles'
          ),
          linkIcon: 'external-link',
          linkUrl:
            'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/users#project-role',
        }
      );

      this.router.transitionTo('cloud.access-control.invites.list');
    } catch (e) {
      const error = yield e.json && e.json();
      if (error) {
        if (error.message.includes(EXISTING_EMAIL_ERROR_MESSAGE)) {
          const membersString = error.message.replace(
            EXISTING_EMAIL_ERROR_MESSAGE,
            ''
          );
          const existingMemberEmails = membersString.split(',');
          yield this.displayExistingEmails(existingMemberEmails, 'user');
        }
        if (error.message.includes(EXISTING_PENDING_EMAIL_ERROR_MESSAGE)) {
          const existingPendingMemberEmails = error.details[0].violations;
          yield this.displayExistingEmails(
            existingPendingMemberEmails,
            'invite'
          );
        } else {
          this.onInviteError();
        }
      } else {
        this.onInviteError();
      }
    }
  }

  onInviteError = () => {
    this.flashMessages.error(
      this.intl.t(
        'components.page.access-control.users.invite.error.sending-invitations-failed'
      ),
      {
        content: this.intl.t(
          'components.page.access-control.users.invite.error.something-went-wrong'
        ),
        linkIcon: 'help',
        linkIconPosition: 'leading',
        linkText: this.intl.t(
          'components.page.access-control.users.invite.error.contact-support'
        ),
        linkUrl: this.supportTicketHref,
      }
    );
  };

  get noRoleUsers() {
    return this.users.filter((invitee) => {
      return !invitee.role;
    });
  }
}
