/**
 * ******************************** DO NOT MANUALLY EDIT THIS FILE ********************************
 * This script is run by the backup-launch-darkly-defaults github action.
 * It fetches flags from LaunchDarkly, grabs the default for either the 'off' variation
 * or the 'fallthrough' variation. The script then writes the results to
 * hcp/app/utils/launch-darkly-defaults.ts so that it can be consumed in the hcp application route.
 * ******************************** DO NOT MANUALLY EDIT THIS FILE ********************************
 */

interface LaunchDarklyDefaults {
  [key: string]: unknown;
}

const defaults: LaunchDarklyDefaults = {
  'consul-recommended-version': 'v1.17.3',
  'hcp-quota-consul-clusters': 6,
  'hcpv-quota-vault-clusters': 6,
  'hcp-ui-consul-quick-start': false,
  'hcp-quota-network': 5,
  'hcp-quota-organizations': 1,
  'hcp-ui-copy-access-token': false,
  'hcp-quota-service-principals': 5,
  'hcpv-aws-available-regions': [
    'us-west-2',
    'us-east-1',
    'us-east-2',
    'ca-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-central-1',
    'ap-northeast-1',
    'ap-southeast-1',
    'ap-southeast-2',
  ],
  'hcp-ui-consul-create-page-displayable-tiers': [
    'hashicorp.consul.development',
    'hashicorp.consul.standard',
    'hashicorp.consul.plus',
    'hashicorp.consul.premium',
  ],
  'hcp-ui-consul-federation': true,
  'hcp-account-settings-mfa': true,
  'hcp-consul-aws-available-regions': [
    'us-west-2',
    'us-east-1',
    'eu-west-1',
    'eu-west-2',
    'eu-central-1',
    'ap-southeast-1',
    'ap-southeast-2',
    'us-east-2',
    'ap-northeast-1',
    'ca-central-1',
  ],
  'hcp-consul-federation-development-secondary-quota': 2,
  'hcp-billing-trial-cluster-quota': 3,
  'hcp-aws-singapore-region-support': true,
  'hcp-aws-sydney-region-support': true,
  'hcp-ui-monitoring-consul-data-plane-audit-log-archives': true,
  'hcp-ui-consul-billing-cost-estimator': true,
  'hcp-ui-vault-billing-cost-estimator': true,
  'hcp-ui-consul-version-upgrades': true,
  'hcp-azure-integration': true,
  'hcp-aws-available-regions': [
    'us-west-2',
    'us-east-1',
    'us-east-2',
    'ca-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-central-1',
    'ap-northeast-1',
    'ap-southeast-1',
    'ap-southeast-2',
  ],
  'hcpv-quota-vault-secondary-clusters': 5,
  'hcp-consul-available-providers': ['aws', 'azure'],
  'hcp-azure-available-regions': [
    'westus2',
    'westeurope',
    'eastus',
    'northeurope',
    'centralus',
    'eastus2',
    'francecentral',
    'uksouth',
    'canadacentral',
    'australiasoutheast',
    'japaneast',
    'southeastasia',
  ],
  'hcp-ui-consul-create-page-azure-displayable-tiers': [
    'hashicorp.consul.development',
    'hashicorp.consul.standard',
    'hashicorp.consul.plus',
    'hashicorp.consul.premium',
  ],
  'hcp-ui-consul-azure-available-packages': [
    'hashicorp.consul.development',
    'hashicorp.consul.standard',
    'hashicorp.consul.premium',
    'hashicorp.consul.plus',
  ],
  'hcp-ui-global-message': {},
  'hcp-ui-maintenance-mode': false,
  'hcp-ui-consul-terraform-quickstart-azure': true,
  'hcp-ui-ab-test-hvn-activation-create': 'onboarding-stepper',
  'hcp-ui-maintenance-mode-consul': false,
  'hcp-ui-maintenance-mode-billing': false,
  'hcp-ui-maintenance-mode-boundary': false,
  'hcp-ui-maintenance-mode-packer': false,
  'hcp-ui-maintenance-mode-vault': false,
  'hcp-ui-maintenance-mode-waypoint': false,
  'hcp-ui-maintenance-mode-iam': false,
  'hcp-quota-projects': 10,
  'hcp-consul-activation': true,
  'hcp-quota-global-network-manager-clusters': 6,
  'hcp-ui-monitoring-consul-platform-logs': true,
  'hcp-vagrant-beta': false,
  'hcp-ui-monitoring-log-streaming': true,
  'hcpv-link-visibility': false,
  'hcp-consul-activation-phase-2': true,
  'hcp-quota-peerings-v2': 10,
  'hcp-hvn-guided-flow': false,
  'hcp-ui-log-streaming-test-connection': true,
  'hcp-ui-log-streaming-edit-connection': true,
  'hcp-project-delete-enabled': true,
  'hcp-maintenance-window-update': true,
  'hcp-ui-log-streaming-grafana': false,
  'hcp-ui-log-streaming-splunk': false,
  'hcp-ui-consul-cluster-peering-phase-3': true,
  'hcp-ui-platform-log-filtering': false,
  'hcp-azure-available-vnet-regions': [
    'eastus',
    'eastus2',
    'southcentralus',
    'westus2',
    'westus3',
    'northeurope',
    'swedencentral',
    'uksouth',
    'westeurope',
    'centralus',
    'canadacentral',
    'francecentral',
    'germanywestcentral',
    'norwayeast',
    'australiasoutheast',
    'japaneast',
    'singapore',
    'australiaeast',
    'eastasia',
    'centralindia',
  ],
  'hcp-aws-available-vpc-regions': [
    'af-south-1',
    'eu-north-1',
    'ap-south-1',
    'eu-west-3',
    'eu-west-2',
    'eu-south-1',
    'eu-west-1',
    'ap-northeast-2',
    'me-south-1',
    'ap-northeast-1',
    'sa-east-1',
    'ca-central-1',
    'ap-east-1',
    'ap-southeast-1',
    'ap-southeast-2',
    'eu-central-1',
    'us-east-1',
    'us-east-2',
    'us-west-1',
    'us-west-2',
  ],
  'hcp-ui-consul-observability-dashboard': true,
  'hcpb-plus-sku-enabled': true,
  'hcp-ui-consul-cluster-peering-ga': true,
  'hcp-ui-consul-ccm-link-existing-clusters-helm-bootstrap': false,
  'hcp-ui-consul-ccm-link-existing-clusters-vm-bootstrap': false,
  'hcpv-observability-chronicle': false,
  'hcp-ui-consul-unification-services-list': true,
  'hcp-ui-fine-grained-roles': true,
  'hcp-ui-billing-self-serve-contract-activation': true,
  'hcp-identity-scim-enabled': false,
  'hcp-ui-consul-service-instances': true,
  'hcp-ui-consul-observability-ga': true,
  'hcp-ui-consul-observability-linking': true,
  'hcp-ui-monitoring-org-and-project-platform-logs': false,
  'hcp-waypoint-actions': true,
  'hcp-ui-azure-hub-spoke': false,
  'hcp-ui-terraform-dashboard-tile': true,
  'hcp-ui-consul-topology': false,
  'hcp-ui-consul-tls-rotation': false,
  'hcp-tfc-unified-experience': false,
  'hcp-ui-billing-geo-support': false,
  'hcp-ui-billing-stripe-invoices-available-for-download': false,
  'hcp-iam-sso-user-deletion': true,
  'hcp-id-marketing-email-geolocation': false,
  'hcp-vault-radar-event-filters-beta': true,
  'hcp-vault-radar-visibility-options-migration': true,
  'hcpv-disaster-recovery-hvn': false,
  'hcp-identity-sso-default-role': false,
  'hcp-vault-radar-user-interface-show-event-confidence': false,
  'hcp-vault-radar-user-interface-use-resources': true,
  'hvs-command-bar': true,
  'hcp-ui-consul-topology-private-beta-early-access': false,
  'hcp-ui-consul-topology-private-beta': false,
  'hcp-identity-sso-default-role-empty-string-no-role': false,
  'hcp-ui-consul-link-cluster-from-self-managed': false,
  'hcp-vault-radar-user-interface-onboard-confluence-cloud': true,
  'hcp-vault-radar-user-interface-onboard-confluence-server': true,
  'hcp-ui-iam-engine': false,
  'hcp-vault-radar-user-interface-cli-instructions': true,
  'mock-flex-contract': false,
  'hcpb-ui-on-demand-updates': true,
  'hcp-rebrand-milestone-0': true,
  'hcp-vault-radar-user-interface-use-resource-id': false,
  'hvs-list-pagination': false,
  'hcp-ui-group-permission-assignments-enabled': false,
  'hcp-ui-better-role-assignments-enabled': true,
  'hcp-vault-radar-user-interface-show-station-onboarding': false,
  'hcp-vault-radar-user-interface-use-data-source-hosts': true,
  'hcp-vault-secrets-dynamic-aws': false,
  'hcp-vault-secrets-dynamic-gcp': false,
  'hcp-vault-radar-abac-workflow': false,
  'hcp-vagrant-registry-create': false,
  'hvs-github-sync-improvement-phase-2': false,
  'hvs-webhook': false,
  'hcp-ui-fine-grained-denied-roles': [
    'roles/iam.*',
    'roles/vault-radar.resource-*',
    'roles/vault-radar.developer',
    'roles/vault-radar.cli-user',
    'roles/vault-radar.scan-target-*',
    'roles/vault-radar.agent',
    'roles/secrets.gateway-*',
  ],
  'hcp-ui-workload-identity-aws': false,
  'hcp-ui-organization-level-audit-logs-streaming': false,
  'hcp-vault-radar-user-interface-show-env-token-location': true,
  'hcp-vault-radar-user-interface-show-agent-onboarding-options': {
    azure_devops_cloud: true,
    bitbucket_cloud: true,
    bitbucket_server: true,
    confluence_cloud: true,
    confluence_server: true,
    gerrit_server: false,
    github_cloud: true,
    github_enterprise: true,
    gitlab_cloud: true,
    gitlab_onprem: true,
    isProd: false,
  },
  'hcp-ui-browser-role-enabled': true,
  'hcc-decommission-ui-banner': false,
  'hcp-ui-hcc-decommission-banner': true,
  'hcp-vault-radar-user-interface-show-remediation-settings': false,
  'hcp-ui-hcc-decommission': false,
  'hvs-hcp-terraform-sync': false,
  'hvs-rotation-ga': false,
  'hcp-ui-billing-100-projects': false,
  'hcp-ui-role-assignments-engine-projects': true,
  'hcp-ui-role-assignments-engine-organizations': false,
  'hcp-packer-new-build-card': true,
  'hcp-vault-radar-user-interface-show-agent-git-hub-apps': false,
  'hcp-vault-radar-user-interface-show-billing': false,
  'hvs-ui-cost-estimator-enabled': false,
  'hcp-vault-radar-enable-agent-scada': false,
  'hcp-ui-hcc-decommission-date': {
    date: 6,
    month: 7,
    year: 2024,
  },
  'hcp-ui-cookie-auth': false,
  'hcp-iam-include-userinfo-in-getcurrentprincipal': false,
};

export default defaults;
