import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailRoute extends Route {
  @service api;
  @service billing;
  @service abilities;
  @service flashMessages;
  @service router;
  @service userContext;
  @service vaultRadarTenant;

  async model({ project_id }) {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } =
      await this.api.resourceManager.project.projectServiceGet(project_id);
    await this.userContext.setProject(project);

    // Fetch the vault radar tenant data to display tenant status in nav and dashboard
    await this.vaultRadarTenant.fetch();

    if (this.abilities.cannot('view billing-account')) {
      return { organization, project };
    }

    let billing = await this.billing.getBilling(
      this.userContext.organization.id,
      project_id
    );

    // if the billing account is not found, try to create it
    // there are guardrails in place to prevent duplicate billing
    // accounts on the server side in case of the billing service being down
    // (i.e.billing account not being found due to something other than a 404), see:
    // https://github.com/hashicorp/cloud-billing/blob/main/internal/billingaccount/privateapi/create.go#L184
    if (!billing.billingAccount) {
      billing = await this.billing.createDefaultBillingAccount(
        this.userContext.organization.id,
        project_id,
        'US'
      );
    }

    this.userContext.billing = billing;

    return { organization, project };
  }
}
