/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageSignIn` renders a page which redirects you to the auth provider.
 *
 *
 * ```
 * <Page::SignIn />
 * ```
 *
 * @class PageSignIn
 *
 */
/**
 *
 * `error` will be a sign in error.
 * @argument error;
 * @type {String}
 *
 */
export default class PageSignInComponent extends Component {
  @service config;
  @service systemStatus;

  get authenticatorType() {
    return this.config.authenticatorType;
  }

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }
}
