/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

/**
 *
 * `PageOrganizationsDetailBillingCard` is a card for the organization
 * dashboard that renders billing data and a link to the billing page.
 *
 *
 * ```
 * <Page::Organizations::Detail::BillingCard
 *   @billing={{@billing}}
 *   @organization={{@organization}}
 *   @projects-{{@projects}}
 *   @runningStatement={{@runningStatement}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailBillingCard
 *
 */

/**
 * A billing model.
 * @argument billing
 * @type {number}
 */

/**
 * The organization in context.
 * @argument organization
 * @type {Object}
 */

/**
 * The projects associated with the organization.
 * @argument projects
 * @type {Array}
 */

/**
 * The running statement for billing.
 * @argument runningStatement
 * @type {object}
 */

export default class PageOrganizationsDetailBillingCardComponent extends Component {
  @service billing;
  @service systemStatus;
  @service userContext;

  constructor() {
    super(...arguments);

    if (!this.userContext.billing) {
      this.setup.perform();
    }
  }

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }

  get showLoading() {
    return this.setup.isRunning || !this.userContext.billing;
  }

  setup = task(async () => {
    const { organization, projects } = this.args;
    if (organization && projects && projects.length > 0) {
      this.userContext.billing = await this.billing.getBilling(
        organization.id,
        projects[0].id
      );
    }
  });
}
