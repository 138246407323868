import Route from '@ember/routing/route';
import { variation } from 'ember-launch-darkly';

export default class TermsOfServiceRoute extends Route {
  beforeModel() {
    if (variation('hcp-ui-billing-flex-2')) {
      window.location.assign('https://eula.hashicorp.com/OnlineAgreements.pdf');
      // Prevent navigation
      return new Promise(() => {});
    } else {
      return;
    }
  }
}
