/* import __COLOCATED_TEMPLATE__ from './billing-summary-card.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

/**
 *
 * `PageProjectsDashboard::BillingSummaryCard` displays the billing card on the Project dashboard.
 *
 *
 * ```
 * <Page::Projects::Dashboard::BillingSummaryCard
 *   @billing={{this.userContext.billing}}
 *   @organization={{@organization}}
 *   @project={{@project}}
 * />
 * ```
 *
 * @class PageProjectsDashboardBillingSummaryCard
 *
 */
/**
 * A billing model.
 * @argument billing
 * @type {number}
 */
/**
 *
 * `organization` is the organization context.
 * @argument organization;
 * @type {Object}
 *
 */
/**
 *
 * `project` is the project context.
 * @argument project;
 * @type {Object}
 *
 */

export default class PageProjectsDashboardBillingSummaryCardComponent extends Component {
  @service billing;
  @service('resources.project') projects;
  @service userContext;
  @service systemStatus;

  constructor() {
    super(...arguments);

    if (!this.userContext.billing) {
      this.setup.perform();
    }
  }

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }

  get showLoading() {
    return this.setup.isRunning || !this.userContext.billing;
  }

  setup = task(async () => {
    const { organization, project } = this.args;
    if (organization && project) {
      this.userContext.billing = await this.billing.getBilling(
        organization.id,
        project.id
      );
    }
  });
}
