import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency';

export default class VaultRadarTenantService extends Service {
  @service api;
  @service userContext;
  @service abilities;

  @tracked _tenantData = {};
  @tracked _error = null;

  get tenantData() {
    return this._tenantData;
  }

  get error() {
    return this._error;
  }

  get loading() {
    return this.fetchTask.isRunning;
  }

  async getOrCreateTenant() {
    const { organization, project } = this.userContext;
    const { state, type } = this._tenantData;
    const tenantExists = state && type;

    if (tenantExists) {
      return new Promise((resolve) => {
        resolve(this._tenantData);
      });
    }

    const response = await this.api.vaultRadar.tenant.createTenant(project.id, {
      location: {
        organizationId: organization.id,
      },
    });

    this._tenantData = response;
    return response;
  }

  @keepLatestTask
  *fetchTask() {
    const { organization, project } = this.userContext;

    // Return data if already fetched or cannot view vault radar
    if (!project?.id || this.abilities.cannot('view vault-radar')) {
      return this._tenantData;
    }

    try {
      const tenantResponse = yield this.api.vaultRadar.tenant.getTenant(
        project.id,
        {
          location: {
            organizationId: organization.id,
          },
        }
      );
      this._tenantData = tenantResponse;
    } catch (error) {
      this._error = error;
      this._tenantData = {};
    }

    return this._tenantData;
  }

  fetch() {
    return this.fetchTask.perform();
  }
}
